import { Composition, staticFile } from 'remotion';
import { AudioGramSchema, AudiogramComposition, fps } from './Composition';
import './style.css';
import { AudioGramSchema2, DevotionalComposition } from './composition/Devotional';
import { JeannetteComposition } from './composition/Jeannette';
import React, { useEffect, useRef, useState } from 'react';
import subtitles from '../public/info.json'; 

export const obtenerFechaActualEnFormato = ()=> {
	const mesesAbreviados = [
	  'ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN',
	  'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'
	];
  
	const fechaActual = new Date();
	const dia = fechaActual.getDate();
	const mesAbreviado = mesesAbreviados[fechaActual.getMonth()];
  
	const fechaFormateada = `${dia} ${mesAbreviado}`;
	return fechaFormateada;
  }

  export const getJsonFile = async ()=> {
	const fileName=staticFile("info.json");
	const data = await fetch(fileName);
	const text = await data.text();
	return JSON.parse(text)
  }
export const RemotionRoot: React.FC = () => {
  
	console.log('Subtitle::',subtitles.durationInSeconds)

	return (
		<>
			{
				/*
			<Composition
				id="Audiogram"
				component={AudiogramComposition}
				fps={fps}
				width={1080}
				height={1920}
				schema={AudioGramSchema}
				defaultProps={{"audioOffsetInSeconds":0,"audioFileName":staticFile("audio.mp3"),"coverImgFileName":staticFile("cover.jpg"),"titulo":"","versiculo":"MARCOS 15:1-23","fecha":"20 FEB","titleColor":"rgba(186, 186, 186, 0.93)","subtitlesFileName":staticFile("subtitles.srt"),"onlyDisplayCurrentSentence":true,"subtitlesTextColor":"black","subtitlesLinePerPage":1,"subtitlesZoomMeasurerSize":1,"subtitlesLineHeight":160,"waveColor":"blue","waveFreqRangeStartIndex":1,"waveLinesToDisplay":10,"waveNumberOfSamples":"256" as const,"mirrorWave":true,"durationInSeconds":720}}
				// Determine the length of the video based on the duration of the audio file
				calculateMetadata={({ props }) => {
					return {
						durationInFrames: props.durationInSeconds * fps,
						props,
					};
				}}
			/>
		*/

				<Composition
					id="video"
					component={DevotionalComposition}
					fps={fps}
					width={1920}
					height={1080}
					schema={AudioGramSchema2}
					defaultProps={{
						// Audio settings
						audioOffsetInSeconds: 0,
						// Title settings
						audioFileName: staticFile('audio.mp3'),
						coverImgFileName: staticFile('cover.jpg'),
						titulo: subtitles.titulo,
						versiculo: subtitles.versiculo,
						fecha: obtenerFechaActualEnFormato(),
						titleColor: 'rgba(186, 186, 186, 0.93)',
						// Subtitles settings
						subtitlesFileName: staticFile('subtitles.srt'),
						onlyDisplayCurrentSentence: true,
						subtitlesTextColor: 'black',
						subtitlesLinePerPage: 1,
						subtitlesZoomMeasurerSize: 1,
						subtitlesLineHeight: 160,
						// Wave settings
						waveColor: 'blue',
						waveFreqRangeStartIndex: 1,
						waveLinesToDisplay: 10,
						waveNumberOfSamples: '256', // This is string for Remotion controls and will be converted to a number
						mirrorWave: true,
						durationInSeconds: parseInt(`${subtitles.durationInSeconds}`, 10),
					}}
					// Determine the length of the video based on the duration of the audio file
					calculateMetadata={({ props }) => {
						return {
							durationInFrames: props.durationInSeconds * fps,
							props,
						};
					}}
				/>
			}
		</>
	);
};




;
    var _a, _b;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_b = (_a = __webpack_module__.hot.data) === null || _a === void 0 ? void 0 : _a.prevExports) !== null && _b !== void 0 ? _b : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
