import parseSRT, { SubtitleItem } from 'parse-srt';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
	cancelRender,
	continueRender,
	delayRender,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';
import { ensureFont } from './ensure-font';
import { Word } from './Word';

const useWindowedFrameSubs = (
	src: string,
	options: { windowStart: number; windowEnd: number }
) => {
	const { windowStart, windowEnd } = options;
	const config = useVideoConfig();
	const { fps } = config;

	const parsed = useMemo(() => parseSRT(src), [src]);
	return useMemo(() => {
		return parsed
			.map((item) => {
				const start = Math.floor(item.start * fps);
				const end = Math.floor(item.end * fps);
				return { item, start, end };
			})
			.filter(({ start }) => {
				return start >= windowStart && start <= windowEnd;
			})
			.map<SubtitleItem>(({ item, start, end }) => {
				return {
					...item,
					start,
					end,
				};
			}, []);
	}, [fps, parsed, windowEnd, windowStart]);
};

export const PaginatedSubtitles: React.FC<{
	subtitles: string;
	startFrame: number;
	endFrame: number;
	linesPerPage: number;
	subtitlesTextColor: string;
	subtitlesZoomMeasurerSize: number;
	subtitlesLineHeight: number;
	onlyDisplayCurrentSentence: boolean;
}> = ({
	startFrame,
	endFrame,
	subtitles,
	linesPerPage,
	subtitlesTextColor: transcriptionColor,
	subtitlesZoomMeasurerSize,
	subtitlesLineHeight,
	onlyDisplayCurrentSentence,
}) => {
	const frame = useCurrentFrame();
	const windowRef = useRef<HTMLDivElement>(null);
	const zoomMeasurer = useRef<HTMLDivElement>(null);
	const [handle] = useState(() => delayRender());
	const [fontHandle] = useState(() => delayRender());
	const [fontLoaded, setFontLoaded] = useState(false);
	const windowedFrameSubs = useWindowedFrameSubs(subtitles, {
		windowStart: startFrame,
		windowEnd: endFrame,
	});

	const [lineOffset, setLineOffset] = useState(0);

	const currentAndFollowingSentences = useMemo(() => {
		// If we don't want to only display the current sentence, return all the words
		if (!onlyDisplayCurrentSentence) return windowedFrameSubs;

		const indexOfCurrentSentence =
			windowedFrameSubs.findLastIndex((w, i) => {
				const nextWord = windowedFrameSubs[i + 1];

				return (
					nextWord &&
					(w.text.endsWith('?') ||
						w.text.endsWith('.') ||
						w.text.endsWith('!')) &&
					nextWord.start < frame
				);
			}) + 1;

		return windowedFrameSubs.slice(indexOfCurrentSentence);
	}, [frame, onlyDisplayCurrentSentence, windowedFrameSubs]);

	useEffect(() => {
		if (!fontLoaded) {
			return;
		}
		const zoom =
			(zoomMeasurer.current?.getBoundingClientRect().height as number) /
			subtitlesZoomMeasurerSize;
		const linesRendered =
			(windowRef.current?.getBoundingClientRect().height as number) /
			(subtitlesLineHeight * zoom);
 
		const linesToOffset = Math.max(0, linesRendered - linesPerPage);
		setLineOffset(linesToOffset);
		continueRender(handle);
	}, [
		fontLoaded,
		frame,
		handle,
		linesPerPage,
		subtitlesLineHeight,
		subtitlesZoomMeasurerSize,
	]);

	useEffect(() => {
		ensureFont()
			.then(() => {
				continueRender(fontHandle);
				setFontLoaded(true);
			})
			.catch((err) => {
				cancelRender(err);
			});
	}, [fontHandle, fontLoaded]);

	const currentFrameSentences = currentAndFollowingSentences.filter((word) => {
		return word.start < frame;
	}); 

	const item = currentFrameSentences[currentFrameSentences.length-1];

	if (item == null){
		return (<></>)
	}
	//console.log('subtitlesZoomMeasurerSize',subtitlesZoomMeasurerSize )
	return (
		<div
			style={{
				position: 'relative',
				overflow: 'hidden',
				paddingBottom: '1px',
			}}
		>
			<div
				ref={windowRef} 
			>
				 
					<span key={item.id} id={String(item.id)}>
						<Word
							frame={frame}
							item={item}
							// transcriptionColor={transcriptionColor}
						/>{' '}
					</span>
				
			</div>
			<div
				ref={zoomMeasurer}
				style={{
					height: subtitlesZoomMeasurerSize,
					width: subtitlesZoomMeasurerSize,
				}}
			/>
		</div>
	);
};

declare global {
	interface Array<T> {
		findLastIndex(
			predicate: (value: T, index: number, obj: T[]) => unknown,
			thisArg?: unknown
		): number;
	}
}

;
    var _a, _b;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_b = (_a = __webpack_module__.hot.data) === null || _a === void 0 ? void 0 : _a.prevExports) !== null && _b !== void 0 ? _b : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
